import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GalleryLayout from "./galleryLayout"

function Couples() {
  return (
    <GalleryLayout title="Our Couples">
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-1v_jmm6vu.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-2v_uvjwot.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086858/couples/couple-3v_lnhuaf.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086850/couples/couple-4v_ihkxkx.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086871/couples/couple-5v_r3ph7y.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086870/couples/couple-6v_qgridu.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086871/couples/couple-7v_ck6akb.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086873/couples/couple-8v_kkplq9.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086873/couples/couple-9v_x7wdbj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086872/couples/couple-10v_aykcqu.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086874/couples/couple-11v_eraf8p.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086875/couples/couple-12v_gu9jvl.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086876/couples/couple-13v_kimyyj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086877/couples/couple-14v_vudwq5.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-15v_vir0jj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-16v_cpgkui.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-17v_jma8zv.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086879/couples/couple-18v_gejl78.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086879/couples/couple-19v_msas5j.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086880/couples/couple-21v_kczu9v.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-22v_kptiuk.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-23v_j6kn0b.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-24v_b0gnxr.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-25v_fczfyi.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-26v_e4tqjv.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-27v_vybnra.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-28v_rdldua.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-29v_hai6tf.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-30v_lschmu.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-31v_he0liy.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-32v_cmsqfu.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-33v_ghevbw.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-34v_spv30d.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-35v_xdk6z0.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-36v_mow5lr.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-37v_ah2irj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086855/couples/couple-38v_xxjqbf.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-39v_srpyls.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086856/couples/couple-40v_kgluhl.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-41v_qj8qmn.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086856/couples/couple-42v_w03bl6.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-43v_e0tz8l.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-44v_bgj9hp.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-45v_twx43y.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-46v_tlwjw9.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086858/couples/couple-47v_yfvfrj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086859/couples/couple-48v_pfbgpt.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086858/couples/couple-49v_es4j9i.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086860/couples/couple-50v_crie9h.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086859/couples/couple-51v_q4bynl.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086861/couples/couple-52v_ss9wgd.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086863/couples/couple-53v_mornf6.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086863/couples/couple-54v_cuujxr.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086863/couples/couple-55v_hzehlx.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-56v_hggki6.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086868/couples/couple-57v_fxnwpg.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086864/couples/couple-58v_pdu54n.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086864/couples/couple-59v_zw2yfw.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086866/couples/couple-60v_u6f8ky.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086866/couples/couple-61v_equvoe.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-62v_f0apja.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-63v_z2ed2r.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086868/couples/couple-64v_a6rpmf.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086870/couples/couple-65v_iohjbw.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086869/couples/couple-66v_xklpgj.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086869/couples/couple-67v_l7f92r.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086871/couples/couple-68v_prspvl.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086869/couples/couple-69v_qnl0yh.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086869/couples/couple-70v_qzkmee.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086870/couples/couple-71v_sax5up.jpg"
        alt="Wedding couple"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-1h_plzbtj.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-2h_up2lnw.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086856/couples/couple-3h_chxjgi.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086858/couples/couple-4h_qelemj.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-5h_qjgikq.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086871/couples/couple-6h_btgmmd.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086871/couples/couple-7h_tcyy5g.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086872/couples/couple-8h_xu3k4m.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086876/couples/couple-9h_hy2dks.png"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086880/couples/couple-10h_ctmcqn.png"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-11h_vwhu4b.png"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086875/couples/couple-12h_fmvumx.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086876/couples/couple-13h_wi3t72.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086876/couples/couple-14h_abnyii.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-15h_cug6vx.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-16h_nuvoge.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-17h_qqpkrw.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086878/couples/couple-18h_wajra1.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086879/couples/couple-19h_rkmkye.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086879/couples/couple-20h_g3znfm.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086879/couples/couple-21h_mmqxmr.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086850/couples/couple-22h_kpkjpc.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-23h_d0yj7g.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086851/couples/couple-24h_wsotc9.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-25h_bz6s04.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-26h_ufvt1p.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-27h_aae3fz.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-28h_wq0jre.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-29h_z4vhll.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086852/couples/couple-30h_tghg0s.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-31h_xcnoxq.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-32h_vaunfu.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-33h_rvbf7n.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-34h_qbdfpl.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-35h_vqyy13.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086855/couples/couple-36h_ca7xws.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086853/couples/couple-37h_dwkdmt.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-38h_r8xqme.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-39h_vlyob9.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086854/couples/couple-40h_haftkt.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086855/couples/couple-41h_ypbzxa.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086855/couples/couple-42h_cb0hp4.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086856/couples/couple-43h_bwa6ha.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-44h_ml8djm.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-45h_wsqkxo.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-46h_cqfno2.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086857/couples/couple-47h_igzbbm.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086859/couples/couple-48h_olonhk.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086859/couples/couple-49h_f988wt.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086859/couples/couple-50h_vlqxne.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086860/couples/couple-51h_bof1as.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086862/couples/couple-52h_dlj95o.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086861/couples/couple-53h_pb1saj.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086862/couples/couple-54h_wotfua.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086862/couples/couple-55h_upml5e.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086863/couples/couple-56h_tea1ye.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086863/couples/couple-57h_idyyhz.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086865/couples/couple-58h_avgn8q.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-59h_n7cndh.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086865/couples/couple-60h_xcmddd.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086865/couples/couple-61h_jczmd7.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086866/couples/couple-62h_k1gdop.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086868/couples/couple-63h_kyouzr.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086867/couples/couple-64h_d4okf7.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086870/couples/couple-65h_iwizbt.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1602098972/couples/couples-66h_xmmi7f.jpg"
        alt="Wedding couple"
        className="gallery-picture-v"
      />
    </GalleryLayout>
  )
}

export default Couples
